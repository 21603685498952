[...document.querySelectorAll("[data-layer-push]")].forEach(($el) => {
	$el.addEventListener("click", () => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: $el.dataset.event,
			events: {
				category: $el.dataset.category,
				action: $el.dataset.action,
				label: $el.dataset.label,
			},
		});
	});
});

// Hero Slider datalayer tracking
[...document.querySelectorAll("[data-layer-slider]")].forEach((element) => {
	element.addEventListener("click", (event) => {
    window.dataLayer = window.dataLayer || [];
		
    const dataLayerConfig = JSON.parse(element.dataset?.layerSlider);
    const {target} = event;

    if (!dataLayerConfig) {
      return;
    }

		if (target.nodeName === 'A') {
      if(target.classList.contains('c-button')) {
        window.dataLayer.push({
          'event': `${dataLayerConfig.event}_cta`,
          'hero_titel': dataLayerConfig.hero_titel,
          'CTA_naam': target.title || target.querySelector('.c-button__label').innerText,
          'link_URL': target.href
        });
      } else {
        window.dataLayer.push({
          'event': `${dataLayerConfig.event}_link`,
          'hero_titel': dataLayerConfig.hero_titel,
          'link_naam': target.title || target.innerText,
          'link_URL': target.href
        });
      }
    } else if (target.nodeName === 'SPAN' && target.classList.contains('c-button__label')) {
      window.dataLayer.push({
        'event': `${dataLayerConfig.event}_cta`,
        'hero_titel': dataLayerConfig.hero_titel,
        'CTA_naam': target.title || target.innerText,
        'link_URL': target.closest('a').href
      });
    }
	});
});
