class HeroSlider extends HTMLElement {
  connectedCallback() {
    this.currentSlide = 0;
    this.$container = this.querySelector("[data-hero-container]");
    this.$slides = this.querySelectorAll("[data-hero-slide]");
    this.$btnPrev = this.querySelector("[data-hero-btn-prev]");
    this.$btnNext = this.querySelector("[data-hero-btn-next]");
    this.$bullets = this.querySelectorAll("[data-hero-bullet]");

    this.$btnPrev?.addEventListener("click", () => {
      this.setDatalayerPush('links');
      // update index
      this.currentSlide =
        this.currentSlide > 0 ? this.currentSlide - 1 : this.$slides.length - 1;

      this.scrollToCurrentSlide();

      clearInterval(this.timer);
    });

    this.$btnNext?.addEventListener("click", () => {
      this.setDatalayerPush('rechts');
      this.gotoNextSlide();
      clearInterval(this.timer);
    });

    // observe slides with intersection observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // update index
            this.currentSlide = [...this.$slides].findIndex(
              ($slide) => $slide === entry.target
            );

            if (this.$bullets.length > 0) {
              this.updateBullets();
            }
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    [...this.$slides].forEach(($slide) => {
      observer.observe($slide);
    });

    this.timer = setInterval(() => {
      this.gotoNextSlide();
    }, 10000);
  }

  setDatalayerPush(direction) {
    const layerConf = JSON.parse(this.$slides[this.currentSlide]?.dataset?.layerSlider);
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'hero_slider_pijl',
      'hero_titel': layerConf?.hero_titel || "",
      'klik_richting': direction
    });
  }

  gotoNextSlide() {
    // update index
    this.currentSlide =
      this.currentSlide < this.$slides.length - 1 ? this.currentSlide + 1 : 0;
    this.scrollToCurrentSlide();
  }

  scrollToCurrentSlide() {
    const w = this.$container.getBoundingClientRect().width;

    // scroll to slide
    this.$container.scroll({
      left: w * this.currentSlide,
      behavior: "smooth",
    });
  }

  updateBullets() {
    [...this.$bullets].forEach(($bullet) => {
      $bullet.classList.remove("is-active");
    });
    this.$bullets[this.currentSlide].classList.add("is-active");
  }
}

window.customElements.define("hero-slider", HeroSlider);
